body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(215, 208, 255, 0.2) ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes glowing {
  0% { color: rgba(0, 0, 0, 0.3) }
  50% { color: rgba(0, 0, 0, 1) }
  100% { color: rgba(0, 0, 0, 0.3) }
}

.showpad {
  width: 100%;
}
.registerShowPad {
  width: 95%;
}
.registerPad {
  padding-left: "10px";
  padding-top: "10px";
}
.costRewardTextField {
  width: 90%;
}
.show-in-desktop {
  display: none;
}
.show-in-mobile {
  display: block;
}
.feeder-statistics {
  width: 95%;
}
.feeder-url-info {
  display: none;
  text-align: center;
}

@media (min-width: 800px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .registerShowPad {
    width: 70%;
  }
  .registerPad {
    padding-left: "60px";
    padding-top: "50px";
  }
  .costRewardTextField {
    width: 30%;
  }
  .showpad {
    width: 900px;
  }
  .feeder-statistics {
    width: 40%;
  }
  .feeder-url-info {
    display: block;
    text-align: left;
  }
  .show-in-desktop {
    display: block;
  }
  .show-in-mobile {
    display: none;
  }
}